export const langs = [
    {
      title: 'ESPAÑOL',
      short: 'ESP',
      code: 'es'
    },
    {
      title: 'ENGLISH',
      short: 'ENG',
      code: 'en'
    }
];