import {actions} from 'constanst/pages';

const pageReducer = {
  [actions.CHANGE_LANG]: (state, lang) => {
    return { ...state, lang: lang };
  },
  [actions.SETTINGS]: (state, settings) => {
    return { ...state, settings };
  },
  [actions.PAGES_DATA]: (state, data) => {
    return { ...state, ...data };
  },
  [actions.ACTUAL_PAGE]: (state, menu) => {
    return { ...state, menu };
  },
  [actions.SWITCH_THEME]: (state, theme) => {
    if (theme){
      return {...state, theme }
    }
    return {...state, theme: state.theme === 'light'? 'dark':'light'}
  },
}

export default pageReducer;