import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: '1rem',
        maxWidth: '400px',
        margin: '1rem'
    },
    content: {
        paddingLeft: '0rem',
        paddingRight: '0rem',
        '& .title':{
            fontFamily: 'Open Sans, Roboto, sans-serif',
            fontWeight: '600',
            margin: 0,
            fontSize: '1rem',
            marginBottom: '.5rem',
            textAlign: 'left',
        },
        '& .thank':{
            '& svg':{
                fontSize: '4rem',
                color: 'green'
            }
        }
    },
    text: {
        marginTop: '.8rem'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    form_input:{
        backgroundColor: '#ffffff',
        marginBottom: '.5rem',
        '& .MuiInputBase-input':{
            backgroundColor: '#ffffff'
        },
        '& .MuiSelect-root':{
            textAlign: 'left'
        },
        '& .MuiFilledInput-multiline':{
            backgroundColor: '#ffffff'
        }
    }
}));
export default useStyles;