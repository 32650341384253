import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme,props) => ({

    container:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
        minHeight: 'calc(100vh - 165px)',
        minWidth: '320px',
        [theme.breakpoints.up('md')]: {
            minHeight: 'calc(100vh - 210px)',
        }
    }

}))

export default useStyles;