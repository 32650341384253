import React from 'react';
import {openUrl} from 'utils';
import Box from '@material-ui/core/Box';
import {WhatsApp,Instagram,Facebook,Twitter,YouTube} from '@material-ui/icons';
import useStyles from './styles';

const SocialIcons = ({color}) =>{
    const classes = useStyles({color});

    return(<Box className={classes.social_icons} display='flex' justifyContent='center'>
        <WhatsApp onClick={()=>openUrl('https://wa.me/528111272624')} />
        <Instagram onClick={()=>openUrl('https://www.instagram.com/gelattina/')} />
        <Facebook onClick={()=>openUrl('https://www.facebook.com/Gelattina')} />
        <Twitter onClick={()=>openUrl('https://twitter.com/Gelattina')} />
        <YouTube onClick={()=>openUrl('https://www.youtube.com/user/gelattinatv')} />
    </Box>)
}

export default SocialIcons;