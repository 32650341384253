import React, { useEffect, useState, memo, Fragment } from 'react';
import {useLocation} from "react-router-dom";
import fetchData from 'Api';
import { Formik, Field } from 'formik';
import {validEmail} from 'utils';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import MsgDialog from 'components/Dialogs/Msg';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import useStyles from './styles';

const formFields = {
    values: {
        name: "",
        email: "",
        enterprise: ""
    },
    errors:{
        name: {error: false},
        email: {error: false},
        enterprise: {error: false}
    }
}

const NewsForm = ({texts,classes,lang}) => {
    const [submited, setSubmited] = useState(false);
    const [msgDialog,setMsgDialog] = useState({open:false});
    const { fields,sbtbtn } = texts.form;

    const onFormSubmit = (values,{ setSubmitting }) =>{
        let submit = true;
        formFields.errors.name = { error: false }
        if(values.name.length < 5){
            formFields.errors.name = { error: true }
            submit = false;
        }

        formFields.errors.email= { error: false }
        if(validEmail(values.email)){
            formFields.errors.email= { error: true }
            submit = false;
        }

        if(values.enterprise.length < 2){
            formFields.errors.enterprise = { error: true }
            submit = false;
        }

        values.lang = lang;

        setSubmitting(submit);
        if(submit){
            fetchData.post('server/newsletter.php',values).then((rsp)=>{
                const {error,success} = rsp;
                if(error){
                    setSubmitting(false);
                    setMsgDialog({open:true,title:"ERROR!",message:error,onClose:()=>{
                        setMsgDialog({open:false});
                    }});
                }
                if(success){
                    localStorage.setItem('newsletter',true);
                    setSubmited(true);
                }
            });
        }
    }

    if(submited){
        return (<Box textAlign="center" className="thank">
            <CheckCircleOutlineIcon />
            <p>{texts.thanks}</p>
        </Box>);
    }

    return (<Fragment>
        <h2 className="title">{texts.title}</h2>
        <Formik initialValues={formFields.values} onSubmit={onFormSubmit} >
            {({ submitForm, isSubmitting }) => (<Fragment>
                <Field {...formFields.errors.name} component={TextField} className={classes.form_input} name="name" type="text" label={fields[0]} variant="filled" fullWidth />
                <Field {...formFields.errors.email} component={TextField} className={classes.form_input} name="email" type="text" label={fields[1]} variant="filled" fullWidth />
                <Field {...formFields.errors.enterprise} component={TextField} className={classes.form_input} name="enterprise" type="text" label={fields[2]} variant="filled" fullWidth />
                <Button onClick={submitForm} variant="contained" disabled={isSubmitting} fullWidth>{sbtbtn}</Button>
            </Fragment>
            )}
        </Formik>
        <MsgDialog {...msgDialog} />
    </Fragment>)
}

const NewsLetter = memo(({texts,lang}) => {
    const classes = useStyles();
    const location = useLocation();
    const [displayed, setDisplayed] = useState(false);
    const [newsDialog, setNewsDialog] = useState(false);

    useEffect(() => {
        const pass = !["/","/agency","/agencia"].includes(location.pathname);
        if(pass && !displayed){
            const newsletter = localStorage.getItem('newsletter');
            if(newsletter === null){
                const timer = setTimeout(()=>setNewsDialog(true), texts.time*1000);
                setDisplayed(true);
                return ()=>clearTimeout(timer);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location.pathname]);

    return (<Dialog
        aria-labelledby='NewsLetter'
        open={newsDialog}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={()=>setNewsDialog(false)}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <NewsForm texts={texts} classes={classes} lang={lang}  />
        </DialogContent>
    </Dialog>)
});

export default NewsLetter;