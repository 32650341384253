import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme,props) => ({
    top_bar:{
        position: 'relative',
        background: props=>props.backGround,
        backgroundColor: props=>props.backGround,
        boxShadow: 'none',
    },
    tool_bar:{
        justifyContent: 'flex-end'
    },
    menu_icon:{
        color: props=> props.color
    },
    logo:{
        width: '120px',
        marginRight: '.5rem'
    }
}))

export default useStyles;