import React from 'react';
import Container from '@material-ui/core/Container';
import useStyles from './styles';

const AppWrapper = (props) =>{
    const {children,color,backGround} = props;
    const classes = useStyles({color,backGround});
    return <Container maxWidth={false} className={classes.container}>
        {children}
    </Container>
}

export default AppWrapper