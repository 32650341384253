import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme,props) => ({
    social_icons:{
        '& svg': {
            color: props=> props.color,
            fontSize: '1.2rem',
            marginLeft: '.5rem',
            marginRight: '.5rem',
            marginBottom: '.5rem',
            cursor: 'pointer',
            [theme.breakpoints.up('md')]: {
                fontSize: '1.8rem',
            }
        }
    }
}))

export default useStyles;