import React, { useContext } from 'react';
import { StoreContext } from 'GlobalData';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { Mail } from '@material-ui/icons';
import useStyles from './styles';

const ContactButton = ({color}) => {
    const { state } = useContext(StoreContext);
    const classes = useStyles({color});
    let history = useHistory();

    const goToContact = () =>{
        history.push(state.lang === 'es' ? "/contacto":"/contact");
    }

    return(<Button onClick={goToContact} className={classes.contact_button} startIcon={<Mail />} >
        {state.contact.title}
    </Button>)
}

export default ContactButton;