import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme,props) => ({

    footer:{
        position: 'relative',
        width: '100%',
        padding: '1rem .5rem'
    },
    links:{
        fontFamily: 'Open Sans, Roboto, sans-serif',
        color: props=> props.color,
        marginLeft: '.2rem',
        //marginRight: '.5rem',
        fontSize: '.7rem',
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            //marginLeft: '3rem',
            //marginRight: '3rem',
        }
    }

}))

export default useStyles;