import React,{useContext} from 'react';
import {StoreContext} from 'GlobalData';
import { useHistory } from "react-router-dom";
import {langs} from 'constanst/languaje';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const LangItem = ({lang,classes,changeLanguage}) =>{
    const lng=langs.filter(langs=>{
        return lang!==langs.code;
    })[0];
    return <ListItem button className={classes.listItem} onClick={()=>changeLanguage(lng.code)} >{lng.title}</ListItem>
}

const SideMenu = (props) =>{
    const {open,setSideMenu} = props;
    const { state,setGlobalState } = useContext(StoreContext);
    const {menu,lang} = state;
    const classes = useStyles();
    let history = useHistory();

    const goToPage = (opt) =>{
        const {to} = opt
        history.push(to);
        setSideMenu({show:false});
    }

    const changeLanguage = (lang) =>{
        setGlobalState('CHANGE_LANG',lang);
        setSideMenu({show:false});
    }
    
    return (<Drawer classes={{paper:classes.side_menu}} anchor='right' open={open} >
        <Box className={classes.top}>
            <img className={classes.logo} src={`/assets/images/gelattina_white.png`} alt="Gelattina" />
            <IconButton className={classes.close} onClick={()=>setSideMenu({show:false})} edge="end" color="inherit" aria-label="close">
                <CloseIcon />
            </IconButton>
        </Box>
        
        <List>
            {menu.map((opt,indx)=>{
                if(indx>0){
                    return <ListItem button key={`sm${indx}`} className={classes.listItem} onClick={()=>goToPage(opt)}>{opt.page}</ListItem>
                }
                return null;
            })}
            <LangItem lang={lang} classes={classes} changeLanguage={changeLanguage} />
        </List>
    </Drawer>)
}

export default SideMenu;