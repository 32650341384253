
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    side_menu:{
        width: '100%',
        background: 'rgba(0,0,0,0.9)',
        fontFamily: 'Open Sans, Roboto, sans-serif',
        fontWeight: 600,
    },
    top:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '2rem',
    },
    logo:{
        width: '120px',
        marginRight: '.5rem'
    },
    close:{
        color: '#ffffff',
        padding: '.5rem',
        '& .MuiSvgIcon-root':{
            fontSize: '2rem',
            fontWeight: 'bold',
            marginTop: '.2rem'
        }
    },
    listItem:{
        justifyContent: 'flex-end',
        paddingRight: '2rem',
        fontFamily: 'Open Sans, Roboto, sans-serif',
        fontWeight: 'bolder',
        fontSize: '1rem',
        color: '#ffffff',
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem',
        }
    }

}))

export default useStyles;