export const animate = 'animate__animated animate_';
export const actions = {
    CHANGE_LANG:'CHANGE_LANG',
    SETTINGS:'SETTINGS',
    PAGES_DATA:'PAGES_DATA',
    ACTUAL_PAGE:'ACTTUAL_PAGE',
    SWITCH_THEME:'SWITCH_THEME'
}

export const assets = {
    home: '/assets/images/home/',
    about: '/assets/images/about/',
    clients: '/assets/images/clients/',
    projects: '/assets/images/projects/',
    contact: '/assets/images/contact/',
    teams: '/assets/images/team/',
    blog: '/assets/images/blog/'
}

export const blog = "/assets/blog/";