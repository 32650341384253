import React from 'react';
import Container from '@material-ui/core/Container';
import useStyles from './styles';

const PageWrapper = (props) =>{
    const {children} = props;
    const classes = useStyles();
    return <Container maxWidth={false} className={classes.container}>
        {children}
    </Container>
}

export default PageWrapper