import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme,props) => ({
    contact_button:{
        fontFamily: 'Open Sans, Roboto, sans-serif',
        fontWeight: 600,
        color: props=>props.color,
        marginLeft: '.8rem',
        marginRight: '.8rem',
    },
}))

export default useStyles;