import React, { createContext, useReducer, Fragment, useMemo } from 'react';
import pageReducer from './reducers';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider,unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import theme from './theme';

export const StoreContext = createContext();

const initialState = {
  lang: null,
  mainData: false,
  theme: "dark",
  settings: { 
    topBar:{show:true, logo:'red',color:'black',backGround:'none' },
    footer:{ show:true, color:'white' },
    container:{ color:'white',backGround:'none' }
  },
  menu: [],
  footer: []
};


const storeReducer = (state, action) => {
  return pageReducer[action.type](state, action.data);
};

const GlobalData = ({ children }) => {
  const [state, dispatchState] = useReducer(storeReducer, initialState);

  const setGlobalState = (action, data) => {
    dispatchState({ type: action, data: data });
  };

  const memoizedTheme = useMemo(()=>{
    return createMuiTheme({
      ...theme(state.theme),
      palette: {
        type: state.theme
      }
    })
  }, [state.theme]);

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={memoizedTheme}>
        <StoreContext.Provider value={{ state, setGlobalState }}>
          {children}
        </StoreContext.Provider>
      </ThemeProvider>
    </Fragment>
  );
};

export default GlobalData;
