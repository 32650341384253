import React,{Fragment,useState} from 'react';
import { useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SideMenu from 'components/Menu/SideMenu';
import ContactButton from 'components/ContactButton';
import useStyles from './styles';

const TopBar = (props) =>{
  const {logo,showContact} = props;
  const classes = useStyles({...props});
  const [sideMenu,setSideMenu] = useState({open:false});
  let history = useHistory();

  const goHome = () =>{
    history.push("/");
  }

  return (<Fragment>
    <AppBar position="static" classes={{root:classes.top_bar}}>
      <Toolbar variant="dense" classes={{root:classes.tool_bar}}>
        {showContact && <ContactButton color={props.color} />}
        <img onClick={goHome} className={classes.logo} src={`/assets/images/gelattina_${logo}.png`} alt="Gelattina" />
        <IconButton className={classes.menu_icon} onClick={()=>setSideMenu({open:true})} edge="end" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <SideMenu {...sideMenu} setSideMenu={setSideMenu}/>
  </Fragment>)
}

export default TopBar;