import React,{useState} from 'react';
import { useHistory } from "react-router-dom";
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import {ArrowBackIos,ArrowForwardIos} from '@material-ui/icons';
import useStyles from './styles';

const getPageIndex = (menu) =>{
    return menu.findIndex((page)=>page.active===true);
}

const getNewIndex = (menu,newIndex) =>{
    const ttPage=menu.length-1;
    if(newIndex<1){
        return ttPage;
    }
    if(newIndex>ttPage){
        return 1;
    }
    return newIndex;
}

const FooterMenu = (props) =>{
    const {menu,color} = props;
    const classes = new useStyles({color});
    const [title,setTitle] = useState('MENU');
    const isDesktop = useMediaQuery('(min-width:960px)');
    let history = useHistory();

    const goToPage = (opt) =>{
        const {to} = opt
        history.push(to);
    }

    const navigateArrow = (nm) =>{
        const pgIndex = getPageIndex(menu);
        const newIndex= getNewIndex(menu,pgIndex+nm);
        goToPage(menu[newIndex]);
    }

    const onMouseOver = (action,page) =>{
        setTitle(action===1? page:"MENU")
    }

    const getNextPage = (nm) =>{
        const pgIndex = getPageIndex(menu);
        const newIndex = getNewIndex(menu,pgIndex+nm);
        onMouseOver(1,menu[newIndex].page)
    }

    return (<Box className={classes.footer_menu}>
        {isDesktop && <h5 className={classes.title}>{title}</h5>}
        <Box display="flex" justifyContent="center" className={classes.footer_options}>
            <IconButton aria-label="prev page" className={classes.menu_arrows} onClick={()=>navigateArrow(-1)} onMouseOver={()=>getNextPage(-1)} onMouseOut={()=>onMouseOver(0)}>
                <ArrowBackIos />
            </IconButton>
            {isDesktop && menu.map((opt,indx)=>{
                if(indx>0){
                    const classOpts = opt.active? `active ${classes.menu_option}`:classes.menu_option;
                    return <Box key={`fm${indx}`} className={classOpts} onMouseOver={()=>onMouseOver(1,opt.page)} onMouseOut={()=>onMouseOver(0)} onClick={()=>goToPage(opt)} />
                }
                return null;
            })}
            {!isDesktop && <h5 className={classes.title}>{title}</h5>}
            <IconButton aria-label="next page" className={classes.menu_arrows} onClick={()=>navigateArrow(1)} onMouseOver={()=>getNextPage(+1)} onMouseOut={()=>onMouseOver(0)}>
                <ArrowForwardIos />
            </IconButton>
        </Box>
    </Box>)

}

export default FooterMenu;