import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme,props) => ({
    container:{
        backgroundColor: props=>props.color,
        backgroundImage: props=> ((props.backGround!=='none')? `url(assets/images/${props.backGround}.jpg)`:'none'),
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        padding: '0',
        minHeight: '100vh',
        minWidth: '320px',
        overflow: 'hidden'
    }
}))

export default useStyles;


        