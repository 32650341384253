import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FooterMenu from 'components/Menu/FooterMenu';
import SocialIcons from 'components/SocialIcons';
import useStyle from './styles';

const Footer = (props) =>{
    const {footer,show,color,menu,lang} = props;
    const classes = useStyle({color});

    const openUrl = (url) =>{
        window.open(url,'_blank');
    }

    if(show){
        return (<Box className={classes.footer}>
            <FooterMenu color={color} menu={menu} />
            {lang === "es" && <SocialIcons color={color} />}
            <Box className={classes.info} display='flex' justifyContent='center'>
                {footer.map((lnks,inx)=>{
                    const {tx,url} = lnks;
                    const props = (url!==undefined)? {onClick:()=>openUrl(url)}:{};
                    return <Typography key={`f${inx}`} className={classes.links} variant="subtitle2" {...props}>{tx}</Typography>
                })}
            </Box>
        </Box>);
    }
    return null;
    
}

export default Footer;