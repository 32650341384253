import React from 'react';
import {Link} from 'react-router-dom';

export const validEmail = (email) =>{
    return !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email);
}

export const validPDF = (type) =>{
    return !/^(\D+\/pdf)$/i.test(type);
}

export const openUrl = (url) =>{
    window.open(url,'_blank');
}

export const createHtmlText = (text) => {
    return {__html: text};
}

export const addLinkToText = (text) => {
    const sptx = text.split('|');
    if(sptx.length>1){
        const newTxt = sptx.map((txt,indx) => {
            const sptx2= txt.split(':');
            if(sptx2.length>1){
                return <Link key={`lk${indx}`} to={sptx2[1]}>{sptx2[0]}</Link>
            }
            return txt;
        });
        return newTxt;
    }else{
        return text;
    }
}

export const changeMetaData = (meta) => {
    document.title=meta.title;
    document.querySelector('meta[property="og:title"]').setAttribute("content",meta.title);
    if(meta.description){
        document.querySelector('meta[name="description"]').setAttribute("content",meta.description);
        document.querySelector('meta[property="og:description"]').setAttribute("content",meta.description);
    }
    document.querySelector('meta[name="keywords"]').setAttribute("content",meta.keywords);
    if(meta.image){
        document.querySelector('meta[property="og:image"]').setAttribute("content",meta.image);
    }
}

export const addZeroToID = (n) => {
    return n < 10 ? `0${n}` : n;
}

export const isValidLang = (lng) =>{
    return /^[es|en]{2}$/.test(lng);
}

export const removeFbChat = (fb , lang) => {
    if (fb.length > 0) {
        if(lang === "en"){
            fb[0].style.display="none";
        }else{
            fb[0].style.display="block";
        }
    }
}