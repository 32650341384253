import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme,props) => ({

    footer_menu:{
        position: 'relative',
        width: '100%',
        marginBottom: '1rem',
        [theme.breakpoints.up('md')]: {
            marginBottom: '.8rem',
        }
    },
    title:{
        fontFamily: 'Open Sans, Roboto, sans-serif',
        textTransform: 'uppercase',
        fontWeight: 600,
        color: props=>props.color,
        textAlign: 'center',
        fontSize: '1rem',
        margin: 0,
        marginBottom: '1px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '.5rem'
        }
    },
    footer_options:{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .active': {
            opacity: '1'
        }
    },
    menu_option:{
        width: '15px',
        height: '15px',
        backgroundColor: props=>props.color,
        borderRadius: '50%',
        marginLeft: '.2rem',
        marginRight: '.2rem',
        cursor: 'pointer',
        opacity: '.2',
        '&:hover': {
            opacity: '1',
        },
        
        [theme.breakpoints.up('md')]: {
            marginLeft: '.5rem',
            marginRight: '.5rem',
        }
    },
    menu_arrows:{
        width: '30px',
        height: '30px',
        color: props=>props.color
    }

}))

export default useStyles;